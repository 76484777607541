import React, { useState, useEffect } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";

// Lib
import { getUser } from "./utils";

// Components
import Loading from "./Loading";

const Form = ({ history }) => {
  const [problem, setProblem] = useState("");
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const user = await getUser();
      setUser(user);
      setTimeout(() => setLoading(false), 2000);
    };
    fetchUser();
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      let send = {
        attachments: [
          {
            author_name: user.name || user.login,
            author_link: user.html_url,
            author_icon: user.avatar_url,
            text: problem
          }
        ]
      };

      await axios.post(
        "https://hooks.slack.com/services/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",
        JSON.stringify(send),
        {
          withCredentials: false,
          transformRequest: [
            (data, headers) => {
              delete headers.post["Content-Type"];
              return data;
            }
          ]
        }
      );

      history.replace("/submitted");
    } catch (error) {
      console.error("ERROR:", error);
    }
  };

  if (loading) return <Loading />;
  if (!user) return <Redirect to="/login" />;
  return (
    <>
      <div className="container col-md-6 col-sm-12 text-center">
        <h1 className="card-title border-title mb-5">
          {user.name || user.login}'s Problem
        </h1>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <textarea
            required
            id="description"
            rows="7"
            type="description"
            className="form-control form-control-lg"
            placeholder="Describe your problem... What is your error? What are you trying to do? What did you find when you researched it?"
            onChange={event => setProblem(event.target.value)}
          />
          <small id="emailHelp" className="form-text text-muted">
            Make sure you tried solving the error before submitting
          </small>
        </div>
        <button type="submit" className="button btn-lg btn-block">
          Send
        </button>
      </form>
    </>
  );
};

export default Form;
