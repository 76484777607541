import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYinYang } from "@fortawesome/free-solid-svg-icons";

const quotes = [
  "A conscience is what hurts when all your other parts feel so good",
  "I’d kill for a Nobel Peace Prize",
  "A conclusion is the part where you got tired of thinking",
  "Research is what I'm doing when I don't know what I'm doing",
  "Light travels faster than sound. This is why some people appear bright until they open their mouths",
  "Money talks. But all mine ever says is goodbye",
  "Life’s like a bird. It’s pretty cute until it poops on your head",
  "A computer once beat me at chess. But it was no match for me at kickboxing",
  "We have enough youth. How about a Fountain of Smart?",
  "My therapist says I have a preoccupation with vengeance. We’ll see about that",
  "I don’t have an attitude problem. You have a perception problem",
  "You don’t need a parachute to go skydiving. You need a parachute to go skydiving twice",
  "Letting go of a loved one can be hard. But sometimes, it’s the only way to survive a rock climbing catastrophe"
];

const Loading = () => (
  <div className="spinner mx-auto text-center">
    <FontAwesomeIcon icon={faYinYang} spin size="4x" />
    <p className="my-5">{quotes[Math.floor(Math.random() * quotes.length)]}</p>
  </div>
);

export default Loading;
