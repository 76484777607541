import axios from "axios";

export const getUser = async () => {
  try {
    const token = localStorage.getItem("token");
    if (!token) return;
    const res = await axios.get(`https://api.github.com/user`, {
      headers: { Authorization: `token ${token}` }
    });
    const user = res.data;
    return user;
  } catch (error) {
    console.error("ERROR:", error);
  }
};
