import React from "react";
import {Link} from 'react-router-dom'

const Submitted = () => {
  return (
    <div className="text-center">
      <h3>Please continue working on your code until we get back to you</h3>
      <Link to="/">
        <button type="submit" className="button btn-lg my-5">
          I'm too lazy to change the URL
        </button>
      </Link>
    </div>
  );
};

export default Submitted;
