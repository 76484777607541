import React from "react";
import GitHubLogin from "react-github-login";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

const Login = ({ history }) => {
  const getToken = async ({ code }) => {
    console.log(code);
    try {
      let res = await axios.get(
        `https://coded-questions-gatekeeper.herokuapp.com/authenticate/${code}`
      );
      const token = res.data.token;
      localStorage.setItem("token", token);
      history.replace("/");
    } catch (error) {
      console.error(error);
    }
  };

  console.log(axios.defaults.headers);

  if (localStorage.getItem("token")) return <Redirect to="/" />;

  return (
    <div className="text-center">
      <div className="btn-group">
        <button className="btn btn-lg btn-light disabled">
          <FontAwesomeIcon icon={faGithub} />
        </button>
        <GitHubLogin
          clientId="a90c9c915c98ef1fd093"
          onSuccess={getToken}
          buttonText="login with github"
          className="btn btn-lg btn-light"
          redirectUri=""
        />
      </div>
    </div>
  );
};

export default Login;
