import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import "./App.css";
import Submitted from "./Submitted";
import Form from "./Form";

import Login from "./Login";

const App = () => {
  return (
    <div className="container col-md-6 col-sm-12 mt-5 mx-auto">
      <div className="card p-lg-5 ">
        <div className="card-body">
          <Switch>
            <Route path="/submitted" component={Submitted} />
            <Route path="/login" component={Login} />
            <Route path="/" component={Form} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default withRouter(App);
